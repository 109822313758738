import React from "react";
import PropTypes from "prop-types";

const ExplainRowBox = ({ children, number }) => {
  return (
    <div className="box relative bg-white rounded-lg w-full h-full lg:pt-full">
      <div
        className="number absolute top-0 left-0 w-12 h-12 text-4xl font-bold flex flex-col items-center justify-center"
        style={{ marginLeft: -10, marginTop: -10 }}
      >
        {number}
      </div>
      <div className="relative lg:absolute left-0 top-0 w-full lg:h-full flex flex-row items-center justify-center">
        <div className="flex flex-col items-center p-1">{children}</div>
      </div>
    </div>
  );
};

ExplainRowBox.propTypes = {
  number: PropTypes.number.isRequired,
};

export default ExplainRowBox;
