import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";

import { ConsentGate } from "@metomic/react";

import Link from "../components/Link";
import LottieWrapper from "../components/LottieVisibilityWrapper";

import SEO from "../components/seo";

import womanWithBookAnimation from "../animations/woman-with-book.json";
import peopleSittingOnATable from "../animations/people-sitting-on-a-table.json";
import ExplainRow from "../components/explain-row/explainRow";
import WeWorkLogo from "../images/testimonials/wework/wework-logo.png";
import ExternalLink from "../components/ExternalLink";
import ConversionRow from "../components/ConversionRow";

import BlockerGoogleCalendarVideo from "../images/blocker-google-calendar.mp4";
import RecommendationVideo from "../images/recommendation.mp4";

import LupeImage from "../images/lupe.png";
import BellImage from "../images/bell.png";
import CheckMarkImage from "../images/checkmark.png";

import AnimationEmailReminders from "../components/animation-items/AnimationEmailReminders";
import VideoContainer from "../components/VideoContainer";
import { isScreenLG, DesktopQuery } from "../lib/utils";
import FormattedHTMLMessage from "../components/FormattedHTMLMessage";

const IndexPage = () => {
  return (
    <>
      <SEO title="index.seo.title" description="index.seo.description" />
      <div className="index">
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <>
              <div className="bg-cream relative">
                <div className="container mx-auto">
                  <div className="relative flex flex-col-reverse lg:flex-row lg:flex-no-wrap z-20 relative justify-between items-center pt-32 pb-0 py-24 lg:py-40 min-h-4/5vh">
                    <div className="flex flex-col w-full lg:w-px lg:block left-0 bottom-0">
                      <div className="flex flex-row justify-center items-end relative lg:absolute bottom-0 mt-3 lg:mt-0">
                        <LottieWrapper
                          animationData={womanWithBookAnimation}
                          className="animation"
                          visible={isVisible}
                          context="lading-hero"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col flex-grow w-full lg:w-auto items-center xl:mb-8 justify-center">
                      <h1 className="text-center leading-tight mb-6">
                        <FormattedMessage id="index.headline" />
                      </h1>
                      <span className="sub-headline">
                        <FormattedHTMLMessage id="index.sub-headline" />
                      </span>

                      <div className="mt-12 flex flex-row items-center">
                        <ExternalLink
                          to="https://meet.appointer.com"
                          className="button shadow-none button-xl mx-2 ga-start-now-button"
                        >
                          <FormattedMessage id="common.start-scheduling" />
                        </ExternalLink>
                      </div>
                    </div>

                    <DesktopQuery>
                      <div
                        style={{ width: 1, minHeight: 400 }}
                        className="flex flex-col"
                      >
                        <div className="absolute bottom-0 mb-2 right-0 people-sitting-on-a-table-animation">
                          <LottieWrapper
                            animationData={peopleSittingOnATable}
                            className="animation"
                            visible={isVisible}
                            context="lading-hero"
                          />
                        </div>
                      </div>
                    </DesktopQuery>
                  </div>

                  <div className="absolute w-full left-0 bottom-0 pb-4 xl:pb-5">
                    <div className="divider" />
                  </div>
                </div>
              </div>
            </>
          )}
        </VisibilitySensor>

        <div className="mt-16 container mx-auto flex flex-col items-center">
          <h2>
            <FormattedMessage id="index.explain-row" />
          </h2>
          <ExplainRow />
        </div>
        <div className="mt-16 bg-mint-500 py-16">
          <div className="container mx-auto flex flex-col items-center">
            <h2 className="text-center mb-12" style={{ maxWidth: 600 }}>
              <FormattedMessage id="index.video-header" />
            </h2>

            <div className="video-container shadow-lg rounded-lg overflow-hidden w-full bg-white -mb-64">
              <ConsentGate micropolicy="media" placeholder="@metomic/youtube">
                <iframe
                  title="youtube"
                  src="https://www.youtube.com/embed/5jDVqmYBcHo?rel=0"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </ConsentGate>
            </div>
          </div>
        </div>
        <div className="container mx-auto flex flex-col items-center mt-64 pt-24">
          <h3 className="mb-8 text-center">
            <FormattedMessage id="index.customer" />
          </h3>
          <img src={WeWorkLogo} width={200} alt="wework logo" />
        </div>
        <div className="container mx-auto">
          <div className="flex flex-row flex-wrap mt-32 p-4">
            <div className="flex flex-col items-center md:items-start w-full md:w-1/3">
              <img src={LupeImage} alt="" className="w-16 mb-4" />
              <h2 className="text-center md:text-left text-2xl lg:text-4xl">
                <FormattedMessage id="index.feature-1" />
              </h2>
              <div className="flex flex-col w-full md:w-auto my-4 lg:my-12">
                <Link
                  to="/why-appointer/"
                  className="button shadow-none button-xl ga-find-out-more-button"
                >
                  <FormattedMessage id="common.find-out-more" />
                </Link>
              </div>
            </div>
            <div className="flex flex-col w-full md:w-2/3 min-h-24">
              <div className="md:px-6">
                <VideoContainer
                  src={RecommendationVideo}
                  className="bg-white rounded-lg shadow-lg"
                  loop
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-row flex-wrap-reverse mt-32 p-4 rounded-lg">
            <div className="flex flex-col w-full md:w-2/3 md:pr-12">
              <div className="md:px-6">
                <VideoContainer
                  src={BlockerGoogleCalendarVideo}
                  className="bg-white rounded-lg shadow-lg"
                  loop
                />
              </div>
            </div>
            <div className="flex flex-col items-center md:items-start w-full md:w-1/3">
              <img src={CheckMarkImage} alt="" className="w-12 mb-5" />
              <h2 className="text-center md:text-left text-2xl lg:text-4xl">
                <FormattedMessage id="index.feature-2" />
              </h2>
              <div className="flex flex-col w-full md:w-auto my-4 lg:my-12">
                <Link
                  to="/why-appointer/"
                  className="button shadow-none button-xl ga-find-out-more-button"
                >
                  <FormattedMessage id="common.find-out-more" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <VisibilitySensor partialVisibility={isScreenLG()}>
          {({ isVisible }) => (
            <div className="container mx-auto">
              <div className="flex flex-row flex-wrap mt-32 p-4 rounded-lg">
                <div className="flex flex-col items-center md:items-start w-full md:w-1/3">
                  <img src={BellImage} alt="" className="w-12 mb-5" />
                  <h2 className="text-center md:text-left text-2xl lg:text-4xl">
                    <FormattedMessage id="index.feature-3" />
                  </h2>
                  <div className="flex flex-col w-full md:w-auto my-4 lg:my-12">
                    <Link
                      to="/why-appointer/"
                      className="button shadow-none button-xl ga-find-out-more-button"
                    >
                      <FormattedMessage id="common.find-out-more" />
                    </Link>
                  </div>
                </div>
                <div className="flex flex-col justify-center w-full md:w-2/3 min-h-24 relative px-6">
                  <AnimationEmailReminders isVisible={isVisible} />
                </div>
              </div>
            </div>
          )}
        </VisibilitySensor>
        <ConversionRow />
      </div>
    </>
  );
};

export default IndexPage;
