import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";
import LottieWrapper from "../LottieVisibilityWrapper";

import appointerCalendarSuggestions from "../../animations/appointer-calendar-suggestions.json";
import appointerAutomaticScheduling from "../../animations/appointer-automatic-scheduling.json";
import initiatorPicksAppointment from "../../animations/initiator-picks-appointment.json";
import ExplainRowBox from "./explainRowBox";

const ExplainRow = () => {
  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => {
        return (
          <>
            <div className="explain-row flex flex-row flex-wrap justify-between w-full">
              <div className="flex flex-col w-full md:w-1/3 p-6">
                <ExplainRowBox number={1}>
                  <LottieWrapper
                    animationData={appointerCalendarSuggestions}
                    className="animation"
                    visible={isVisible}
                    context="explain-row"
                  />
                  <div className="text-xl lg:text-2xl l font-semibold text-center p-4">
                    <FormattedMessage id="components.explain-row.feature-1" />
                  </div>
                </ExplainRowBox>
              </div>
              <div className="flex flex-col w-full md:w-1/3 p-6">
                <ExplainRowBox number={2}>
                  <LottieWrapper
                    animationData={appointerAutomaticScheduling}
                    className="animation"
                    visible={isVisible}
                    context="explain-row"
                  />
                  <div className="text-xl lg:text-2xl font-semibold text-center p-4">
                    <FormattedMessage id="components.explain-row.feature-2" />
                  </div>
                </ExplainRowBox>
              </div>
              <div className="flex flex-col w-full md:w-1/3 p-6">
                <ExplainRowBox number={3}>
                  <LottieWrapper
                    animationData={initiatorPicksAppointment}
                    className="animation"
                    visible={isVisible}
                    context="explain-row"
                  />
                  <div className="text-xl lg:text-2xl font-semibold text-center p-4">
                    <FormattedMessage id="components.explain-row.feature-3" />
                  </div>
                </ExplainRowBox>
              </div>
            </div>
          </>
        );
      }}
    </VisibilitySensor>
  );
};

export default ExplainRow;
